import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import NakaLightbox from '../../../components/NakaLightbox'
import Layout from '../../../components/layout'
import GbvMenu from './_menu.js'

const Gbv2022TourPage = ({ data }) => (
  <Layout bgClass="clients-gbv">
    <GbvMenu pageName="2022_tour" />
    <div className="textPageDetails">
      <p>
      Nakatomi was asked to pitch in for Dayton's own Guided By Voices' tour poster series, assisting the band with 23 separate shows. GBV's eclectic and wide-ranging catalog allowed us to showcase diverse designs and artists of all backgrounds. Nakatomi handled artist outreach, art direction, screen printing, and fulfillment for these dates.
      </p>
    </div>
    <div className="gallery-container gbvPage">
    <NakaLightbox images={data.allFile.edges} delimiter=" -- " />
    </div>
  </Layout>
)

Gbv2022TourPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Gbv2022TourPage

export const pageQuery = graphql`
  query Gbv2022TourPageIndexQuery {
    allFile(
      filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativePath: {regex: "/gbv/2022_tour/"}}
      sort: {fields: name}
    ) {
      totalCount
      edges {
        node {
          childImageSharp {
            id
            sizes(maxWidth: 1800) {
              ...GatsbyImageSharpSizes
            }
            fluid {
              srcSet
              src
              originalName
              presentationWidth
              presentationHeight
              originalImg
            }
          }
        }
      }
    }
  }
`
