import React from 'react'
import { Link } from 'gatsby'

const GbvMenu = (props) => (
  <div>
  <h2 className="major">Guided By Voices</h2>
  <ul className="bigList">
  <li className={props.pageName === '2022_tour' ? 'current' : ''}><Link to="/clients/gbv/2022_tour">2021 - 2022 Tour</Link></li>
  </ul>
  </div>
)

export default GbvMenu
